import { createFeature, createReducer, on } from '@ngrx/store';
import { ListReadOnlyContainerActions } from '../store/index';
import { ListReadOnlyState } from './list-readOnly.state';
import { addInviteeToList } from 'src/app/core/helpers/helpers';

export const initialState: Readonly<ListReadOnlyState> = {
  list: {
    id: 0,
    name: '',
    maxInvitees: 0,
    price: '',
    namePromoter: '',
    emailPromoter: '',
    link: '',
    validTillDate: '',
    validTillTime: '',
    listType: null,
    event: null,
    listInvitee: [],
  },
  listInvitees: [],
};

export const listReadOnlyReducers = createReducer(
  initialState,
  on(ListReadOnlyContainerActions.initList, (state, { list }) => ({
    ...state,
    list: list,
  })),
  on(ListReadOnlyContainerActions.listOpened, (state, { listInvitees }) => ({
    ...state,
    listInvitees: listInvitees,
  })),
  on(
    ListReadOnlyContainerActions.addInviteeToListClickedReadOnly,
    (state, { listInvitees, listInvitee }) => ({
      ...state,
      list: listInvitee.list!,
      listInvitees: addInviteeToList(listInvitees, listInvitee),
    }),
  ),
);

export const listReadOnlyFeature = createFeature({
  name: 'listReadOnly',
  reducer: listReadOnlyReducers,
});
