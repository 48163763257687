import * as ListReadOnlyContainerActions from './actions/list-readOnly.container.actions';
import { listReadOnlyFeature } from './list-readOnly.reducers';

export * as listReadOnlyState from './list-readOnly.state';

const { reducer, name, ...listReadOnlySelectors } = listReadOnlyFeature;

export {
  reducer as listReadOnlyReducer,
  name as listReadOnly,
  ListReadOnlyContainerActions,
  listReadOnlySelectors,
};
