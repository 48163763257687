import { createAction, props } from '@ngrx/store';
import { List, ListInvitee } from '../../../../core/models/app.models';
import { ImportResult } from 'src/app/features/crm/models/import-result.interface';

export const addInviteeToListClickedReadOnly = createAction(
  '[List ReadOnly Container] Add Invitee to List ReadOnly Clicked',
  props<{
    listInvitees: ListInvitee[];
    listInvitee: ListInvitee;
  }>(),
);
export const initList = createAction(
  '[List ReadOnly Container] Init List',
  props<{ list: List }>(),
);
export const addInviteeToListSuccessfulReadOnly = createAction(
  '[List ReadOnly Container] Add Invitee To List ReadOnly Successful',
);
export const addInviteeToListUnsuccessfulReadOnly = createAction(
  '[List ReadOnly Container] Add Invitee To List ReadOnly Unsuccessful',
);
export const maxInviteesReachedReadOnly = createAction(
  '[List ReadOnly Container] Max invitees reached',
);
export const currentTimeOverValidTime = createAction(
  '[List ReadOnly Container] Current Time is Over the valid time of list',
);
export const listOpened = createAction(
  '[List ReadOnly Container] List ReadOnly Opened',
  props<{
    listInvitees: ListInvitee[];
  }>(),
);
export const importCsv = createAction(
  '[List ReadOnly Container] Import CSV',
  props<{ file: File }>(),
);
export const importCsvSuccessful = createAction(
  '[List ReadOnly  Container] Import CSV Successful',
);
export const importCsvUnsuccessful = createAction(
  '[List ReadOnly  Container] Import CSV failed',
  props<{ result: ImportResult }>(),
);
export const exportCsvLInviteesFromList = createAction(
  '[List ReadOnly Container] Export CSV',
  props<{ listId: number }>(),
);
export const getCsvTemplateClick = createAction(
  '[List ReadOnly  Container] Export CSV Template',
);
